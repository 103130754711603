.flex-container {
    max-width: 1400px;
    margin: 20px auto;
    padding: 0 20px;
    display: flex;
    gap: 20px;
}

.news-container {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
}

.announcement-section {
    width: 300px;
    flex-shrink: 0;
    position: sticky;
    top: 20px;
    height: fit-content;
    align-self: flex-start;
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.announcement-title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid #f0f0f0;
}

.announcement-item {
    padding: 15px 0;
    border-bottom: 1px solid #f0f0f0;
}

.announcement-item:last-child {
    border-bottom: none;
}

.announcement-item a {
    color: #0066cc;
    text-decoration: none;
    font-size: 14px;
    line-height: 1.5;
    display: block;
}

.announcement-item a:hover {
    color: #004999;
    text-decoration: underline;
}

.news-card {
    flex: 0 0 calc(50% - 10px);
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: transform 0.2s;
    text-align: left;
}

.news-card:hover {
    transform: translateY(-2px);
}

.news-thumbnail {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 15px;
}

.news-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
    text-align: left;
}

.news-headline {
    font-size: 16px;
    color: #666;
    margin-bottom: 15px;
    text-align: left;
}

.news-publish-date {
    font-size: 14px;
    color: #888;
    text-align: left;
}

/* Responsive design */
@media (max-width: 1200px) {
    .news-card {
        flex: 0 0 calc(50% - 10px);
    }
}

@media (max-width: 992px) {
    .flex-container {
        flex-direction: column;
    }

    .announcement-section {
        width: 100%;
        position: static;
    }

    .news-card {
        flex: 0 0 calc(50% - 10px);
    }
}

@media (max-width: 768px) {
    .flex-container {
        padding: 0 10px;
    }

    .news-card {
        flex: 0 0 100%;
    }

    .news-thumbnail {
        height: 200px;
    }

    .news-title {
        font-size: 20px;
    }

    .news-headline {
        font-size: 14px;
    }
} 