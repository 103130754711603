.footer-container {
    background-color: #1c1b31; /* Dark background color */
    padding: 50px 0;
    color: #fff; /* White text */
  }
  
  .footer-logo-img {
    width: 180px;
    height: auto;
    max-width: 100%;
  }
  
  .footer-title {
    font-size: 1.2rem;
    margin-bottom: 15px;
    font-weight: bold;
    color: #ffffff;
    text-align: left;
    margin-bottom: 20px;
    padding-left: 0;
  }
  
  .footer-list {
    padding: 0;
    list-style: none;
    text-align: left;
  }
  
  .footer-list li {
    margin-bottom: 10px;
    text-align: left;
  }
  
  .footer-list li a {
    text-decoration: none;
    color: #ffffff;
    font-size: 1rem;
    text-align: left;
  }
  
  .footer-list li a:hover {
    text-decoration: underline;
    color: #f0f0f0;
  }
  
  /* Adjust for mobile */
  @media (max-width: 768px) {
    .footer-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 30px;
    }
  
    .footer-logo-img {
      width: 150px;
      margin: 0 auto; /* Center the logo */
    }
  
    .footer-title {
      font-size: 1.1rem;
      text-align: center; /* Center titles on mobile */
    }
  
    .footer-list {
      text-align: center; /* Center list items on mobile */
    }
  
    .footer-list li {
      text-align: center;
    }
  
    .footer-list li a {
      font-size: 0.9rem;
      display: inline-block; /* Helps with centering */
    }
  
    /* Center language selector on mobile */
    .col-lg-3:last-child .footer-title {
      text-align: center;
    }
  
    .language-selector {
      justify-content: center;
    }
  
    /* Adjust container padding for mobile */
    .footer-container {
      padding: 30px 15px;
    }
  
    /* Center all column content on mobile */
    .col-md-6 {
      text-align: center;
    }
  
    /* Center language selector container */
    .language-selector {
      justify-content: center !important;
    }
  }
  
  /* Additional mobile optimization */
  @media (max-width: 576px) {
    .footer-logo-img {
      width: 120px; /* Slightly smaller on very small screens */
    }
  
    .footer-container {
      padding: 20px 10px;
    }
  }
  
  .language-toggle {
    display: flex;
    align-items: center;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-right: 10px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .language-selector button:hover {
    background: #7b1fa2;
    color: white;
  }
  
  .language-selector button:focus {
    outline: none;
  }
  
  .social-icons {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .social-icons a {
    width: 45px;
    height: 45px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .social-icons a:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  }
  
  /* Social Media Icon Colors */
  .social-icons .facebook {
    background-color: #1877F2;
  }
  
  .social-icons .instagram {
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  }
  
  .social-icons .linkedin {
    background-color: #0A66C2;
  }
  
  .social-icons .youtube {
    background-color: #FF0000;
  }
  
  /* Mobile Responsive */
  @media (max-width: 768px) {
    .social-icons {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
    }
    
    .social-icons a {
      width: 40px;
      height: 40px;
    }
  }
  
  /* Social Media section specific alignment */
  .col-lg-3 .footer-title {
    text-align: center;
  }
  
  /* Language section specific alignment */
  .col-lg-3:last-child .footer-title {
    text-align: left;
  }
  
  .footer-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    text-align: left;
    color: #ffffff;
    font-family: inherit;
    font-size: 1rem;
    font-weight: normal;
  }
  
  .footer-button:hover {
    color: #f0f0f0;
    text-decoration: underline;
  }
  
  /* Remove any box or border styles */
  .footer-button, 
  .footer-button:focus,
  .footer-button:active {
    box-shadow: none;
    outline: none;
    background: transparent;
  }
  