/* Custom font size for different screens */
.login-title {
  font-size: 1rem; /* Default size */
}

.login-subtitle {
  font-size: 1.2rem;
}
.btns{
  font-size: 12px;
  width: 120px;
  margin: 5px;
  flex: 1;
  color: '#6B4190';
}
.btns:hover{
  color: white;

}
.control{
  font-size: 1rem; /* Increased size */
}
.cont-btn{
  font-size: 0.9rem; /* Increased size */
}
/* Custom styles for the modal */
.custom-modal .modal-content {
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 900px; /* Further increased width */
}

.custom-modal .modal-body {
  border-radius: 15px;
  padding: 20px;
  height: 600px; /* Further increased height */
}
.img-fluid{
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 100%; /* Ensure image covers the full height */
}

.d-flex {
  flex-wrap: wrap; /* Allow wrapping of buttons */
  justify-content: center; /* Center buttons */
}

@media (min-width: 768px) {
  .login-title {
    font-size: 1.7rem; /* Larger font on medium+ screens */
  }

  .login-subtitle {
    font-size: 1.2rem; /* Larger font on medium+ screens */
  }
}

@media(max-width:991px){
  .sign{
    margin-bottom: 12px;
    font-weight: bold;
  }
  .login{
    margin-left: 12px;
   font-size: 12px;
  }
}

/* Hide image on small screens */
@media (max-width: 768px) {
  .img-fluid {
    display: none;
  }
}