.banner {
  transition: all 0.3s ease;
  border-radius: 20px;
}

.banner-title-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 2;
  padding: 0 20px;
  text-align: center;
}

.banner-title {
  color: #ffffff;
  font-size: 48px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  margin: 0;
}

@media (max-width: 991px) {
  .banner-title-wrapper {
    top: 55%; /* Adjust position for mobile */
  }
  
  .banner-title {
    font-size: 32px; /* Smaller font size for mobile */
  }
}

/* Remove any clip-path properties if they exist */
