.faq-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
  }
  
  .faq-title {
    text-align: center;
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 40px;
    color: #000;
  }
  
  .search-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto 40px;
  }
  
  .search-wrapper {
    position: relative;
    width: 100%;
  }
  
  .search-input {
    width: 100%;
    padding: 15px 45px;
    border: 1px solid #e0e0e0;
    border-radius: 50px;
    font-size: 16px;
    outline: none;
    background: white;
  }
  
  .search-icon {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
  }
  
  .faq-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0;
  }
  
  .faq-item {
    background: white;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0,0,0,0.05);
    transition: all 0.3s ease;
    margin: 0;
  }
  
  .faq-item:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  }
  
  .faq-question {
    padding: 20px 25px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border: none;
  }
  
  .faq-question h5 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #333;
  }
  
  .faq-toggle-symbol {
    font-size: 24px;
    color: #666;
    font-weight: 300;
  }
  
  .faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-out;
    background-color: white;
    opacity: 0;
  }
  
  .faq-answer.show {
    max-height: 1000px;
    padding: 0 25px 20px;
    opacity: 1;
  }
  
  .faq-answer p {
    margin: 0;
    color: #666;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .faq-container {
      padding: 20px 0;
    }
  
    .faq-title {
      font-size: 32px;
      margin-bottom: 30px;
    }
  
    .search-input {
      padding: 12px 40px;
      font-size: 14px;
    }
  
    .faq-question h5 {
      font-size: 16px;
    }
  
    .faq-answer p {
      font-size: 14px;
    }
  
    .faq-list {
      padding: 0 5px;
    }
  
    .faq-item {
      margin: 0 5px;
    }
  }