.not-found-container {
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  .not-found-content {
    text-align: center;
    max-width: 600px;
    padding: 40px;
    background: white;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .error-code {
    font-size: 120px;
    font-weight: bold;
    color: #dc3545;
    margin: 0;
    line-height: 1;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .error-title {
    font-size: 32px;
    color: #343a40;
    margin: 20px 0;
    font-weight: 600;
  }
  
  .error-message {
    font-size: 18px;
    color: #6c757d;
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  .error-actions {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .home-button,
  .contact-button {
    padding: 12px 30px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .home-button {
    background-color: #0d6efd;
    color: white;
    border: 2px solid #0d6efd;
  }
  
  .home-button:hover {
    background-color: #0b5ed7;
    color: white;
    transform: translateY(-2px);
  }
  
  .contact-button {
    background-color: transparent;
    color: #0d6efd;
    border: 2px solid #0d6efd;
  }
  
  .contact-button:hover {
    background-color: #0d6efd;
    color: white;
    transform: translateY(-2px);
  }
  
  /* Mobile Responsive */
  @media (max-width: 768px) {
    .error-code {
      font-size: 100px;
    }
  
    .error-title {
      font-size: 28px;
    }
  
    .error-message {
      font-size: 16px;
    }
  
    .error-actions {
      flex-direction: column;
      gap: 15px;
    }
  
    .home-button,
    .contact-button {
      width: 100%;
    }
  
    .not-found-content {
      padding: 30px 20px;
    }
  }
  
  /* Animation for buttons */
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
  }
  
  .home-button:hover,
  .contact-button:hover {
    animation: bounce 0.5s ease;
  }