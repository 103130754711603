.language-selector-modal .modal-content {
  background: linear-gradient(135deg, #ffffff 0%, #f5f5f5 100%);
  border: none;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.language-selector-modal .modal-header {
  border: none;
  padding: 2rem 2rem 1rem;
}

.language-selector-modal .modal-title h2 {
  color: #333;
  font-weight: 600;
  font-size: 1.8rem;
}

.language-selector-modal .modal-title p {
  color: #666;
  font-size: 1.2rem;
}

.language-selector-modal .modal-body {
  padding: 1rem 2rem 2rem;
}

.language-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 1rem 0;
}

.language-option {
  background: white;
  border: 2px solid #eee;
  border-radius: 15px;
  padding: 1.5rem;
  width: 200px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.language-option:hover {
  border-color: #86469C;
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(134, 70, 156, 0.2);
}

.language-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.language-option h3 {
  color: #333;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.language-option p {
  color: #666;
  margin: 0;
  font-size: 0.9rem;
}

/* Animation for modal appearance */
.modal.show {
  animation: modalFadeIn 0.3s ease-out;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .language-options {
    flex-direction: column;
    align-items: center;
  }

  .language-option {
    width: 100%;
    max-width: 280px;
  }

  .language-selector-modal .modal-title h2 {
    font-size: 1.5rem;
  }

  .language-selector-modal .modal-title p {
    font-size: 1rem;
  }
}
