/* Grid Layout with 4 images per row */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 images per row */
  gap: 20px;
}

/* Uniform gallery item */
.gallery-item {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gallery-img {
  width: 100%;
  height: 300px; /* Set a fixed height to increase the size */
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* Hover Effect */
.gallery-item:hover {
  transform: scale(1.05);
  z-index: 1;
}

.gallery-item:hover .gallery-img {
  transform: scale(1.1);
}

/* Optional shadow effect */
.gallery-item:hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  transition: background 0.3s ease;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .gallery-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 images per row on medium screens */
  }
}

@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 images per row on smaller screens */
  }

  .gallery-section {
    padding: 10px 0;
  }

  .MuiBox-root {
    padding: 0 !important;
    margin: 0 !important;
  }

  /* Remove gap between gallery and events */
  .gallery-events-section {
    margin-top: 0 !important;
  }

  /* Adjust spacing for event cards */
  .event-card {
    margin-bottom: 10px !important;
  }
}

@media (max-width: 576px) {
  .gallery-grid {
    grid-template-columns: repeat(1, 1fr); /* 1 image per row on extra small screens */
  }
}

.gallery-carousel {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.gallery-carousel .carousel-control-prev,
.gallery-carousel .carousel-control-next {
  width: 5%;
  background-color: rgba(0,0,0,0.3);
  border-radius: 50%;
  height: 50px;
  top: 50%;
  transform: translateY(-50%);
}

.gallery-carousel .carousel-indicators {
  bottom: -50px;
}

.gallery-carousel .carousel-indicators button {
  background-color: #666;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
}

.gallery-carousel .carousel-indicators .active {
  background-color: #000;
}

.carousel-caption {
  background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.7));
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
}

.gallery-container {
  position: relative;
  min-height: calc(100vh - 60px); /* Adjust based on your navbar height */
  background-color: #000;
  margin-top: 60px; /* Should match your navbar height */
}

.filter-buttons {
  position: fixed;
  top: 80px; /* Increased to account for navbar */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  padding: 10px;
  border-radius: 8px;
}

.filter-buttons .MuiButton-root {
  background-color: transparent !important;
  color: white !important;
  border: 1px solid white !important;
  margin: 0 5px !important;
  min-width: 100px;
}

.filter-buttons .active {
  background-color: #1976d2 !important;
}

.carousel-wrapper {
  position: fixed;
  top: 60px; /* Should match your navbar height */
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullscreen-carousel {
  width: 100%;
  height: calc(100vh - 60px); /* Adjust based on your navbar height */
}

.carousel-image-container {
  width: 100%;
  height: calc(100vh - 60px); /* Adjust based on your navbar height */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}

.carousel-image {
  max-width: 90%;
  max-height: calc(100vh - 120px); /* Adjust to leave space for navbar and buttons */
  object-fit: contain;
  margin: auto;
}

.no-images {
  color: white;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Fade animation for carousel */
.carousel-item {
  transition: opacity 0.6s ease-in-out !important;
}

.carousel-item.active {
  opacity: 1;
}

.carousel-item:not(.active) {
  opacity: 0;
  position: absolute;
  top: 0;
}

/* Active button state */
.filter-buttons .MuiButton-root.active {
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
}

/* Footer positioning */
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}

/* Button hover effect */
.filter-buttons .MuiButton-root:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.gallery-section {
  padding: 40px 20px;
  position: relative;
  background: white;
}

.gallery-filter-buttons {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.nav-button:hover:not(:disabled) {
  background: #f0f0f0;
}

@media (max-width: 768px) {
  .gallery-container {
    padding: 0 30px;
  }
  
  .nav-button {
    width: 30px !important;
    height: 30px !important;
  }
}
